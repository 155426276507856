<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
      
     
        <el-form-item label="部门名称：">
          <el-input
            size="default"
            v-model.trim="formInline.dept"
            maxlength="60"
            clearable
            placeholder="部门名称"
     
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item label=""
          ><el-button
            @click="search"
            type="primary"
            icon="Search"
            >搜索</el-button
          ></el-form-item
        >
        <el-form-item label=""
          ><el-button @click="handleEdit('', 'reset')" icon="Refresh"
            >重置</el-button
          ></el-form-item
        >
      </el-form>
    </div>
    <div class="tableSelect">
      <el-button @click="addAdmin" type="primary" icon="Plus">创建VIP卡数量</el-button>

    </div>
    <div class="table">
      <el-table
       :height="'calc('+(tableHeight)+'px)'"
        border
        :data="tableData"
        ref="tableref"
        v-loading="loadingPage"
        style="width: 100%"
      >
        <el-table-column
          min-width="150"
          prop="dept"
          label="部门名称"
        ></el-table-column>
        <el-table-column min-width="150" prop="all" label="VIP卡总数">
        </el-table-column>
        <el-table-column min-width="100" prop="empty" label="空白卡数量">
        </el-table-column>
        <el-table-column min-width="100" prop="not_used" label="未使用数量">
          <template #default="scope">
            <span v-if="scope.row.not_used <50 " style="color: red">{{scope.row.not_used  }}</span>
            <span v-else>{{ scope.row.not_used }}</span>
          </template>
        </el-table-column>
               <el-table-column min-width="100" prop="used" label="已使用数量">
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      v-bind:child-msg="pageparm"
      @callFather="callFather"
    ></Pagination>
    <createVip
      ref="createVipref"
      @refreshData="getData"></createVip>
  </div>
</template>
<script>
import createVip from "./createVip.vue";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import {subdept_count, owner_count, singleStdelete, deptproduct, options, singleStput, deptSchoolPeo,groupget ,exportStudents,url_other_} from '@/api/api'
export default {
  components: {
    Pagination,
    createVip
// addpeoGroup
  },
  name: 'numberVip',
  data() {
    return {
      optionschool:[],
      dateList:[],
      props: { multiple: true,value:'id', label:'name'},
      options: [],
      objArrDetle: [],
      userlist: [],// pingce
      productsgetlist: [], // fangam
      isFull: false,
      visible: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
   dept:'',
        page: 1,
        limit: 10,
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [],
      getGroupList:[],
      timers: [],
      tableData: []
    }
  },
  methods: {
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    addAdmin() {
      this.$refs.createVipref.addAdminFn(null, '新增')
    },
    handleEdit(row, flag) {
      if (flag == 'com') {
            window.open( url_other_ + '#/?id=' + row.id, '_blank');
      }
      if (flag == 'open') { 
            window.open( url_other_, '_blank');
      }
      else if (flag == 'edit') {
        this.$refs.addStudentsEvaluateflag.addAdminFn(row, '编辑')
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的任务么？', {
          closeOnClickModal: false
        }).then(res => {
          singleStdelete(row).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'status') {
        let vm = this
        vm.$confirm('您确定要完成选中的任务么？', {
          closeOnClickModal: false
        }).then(res => {
          row['status'] = '3'
          singleStput(row).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }
      else if (flag == "reset") {
        this.optionschool =[]
        this.formInline = {
       dept: '',
        page: 1,
        limit: 10,
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.dateList = []
        this.getData()
      }
    },

    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },

    async getDataproductsget() {
      deptproduct({ page: 1, limit: 999 }).then(res => {
        if (res.code == 2000) {
          this.productsgetlist = res.data.data
        }
      })
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      subdept_count(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data

          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
           let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight) - 50
    }
  },
  created() {
    this.getData()
  },
      mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
              this.getTheTableHeight()
            })
        },
        unmounted() {
              // 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
        },
}
</script>
<style>
</style>
