<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form
        :inline="true"
        class="vipTotal"
        :model="formInline"
        label-position="left"
      >
        <el-form-item label="VIP卡总数：">
          <span class="spanVip">{{ allData.all }}</span>
        </el-form-item>
        <el-form-item label="已使用数量：">
          <span class="spanVip">{{ allData.used }}</span>
        </el-form-item>
        <el-form-item label="未使用数量：">
          <span class="spanVip">{{ allData.not_used }}</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="使用部门：">
          <el-input
            size="default"
            v-model.trim="formInline.owner_name"
            maxlength="60"
            clearable
            placeholder="使用部门"
            style="width: 170px"
          ></el-input>
        </el-form-item>
        <el-form-item label="测评用户名称：">
          <el-input
            size="default"
            v-model.trim="formInline.user_name"
            maxlength="60"
            clearable
            placeholder="测评用户名称"
            style="width: 170px"
          ></el-input>
        </el-form-item>
        <el-form-item label="使用状态：">
          <el-select
            style="width: 170px"
            v-model="formInline.status"
            clearable
            placeholder="请选择"
          >
            <el-option label="已使用" value="used"> </el-option>
            <el-option label="未使用" value="not_used"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="VIP卡号：">
          <el-input
            size="default"
            v-model.trim="formInline.vip"
            maxlength="60"
            clearable
            placeholder="VIP卡号"
            style="width: 170px"
          ></el-input>
        </el-form-item>
        <el-form-item label=""
          ><el-button @click="search" type="primary" icon="Search"
            >搜索</el-button
          ></el-form-item
        >
        <el-form-item label=""
          ><el-button @click="handleEdit('', 'reset')" icon="Refresh"
            >重置</el-button
          ></el-form-item
        >
      </el-form>
    </div>
    <div class="table">
      <el-table
        :height="'calc(' + tableHeight + 'px)'"
        border
        :data="tableData"
        ref="tableref"
        v-loading="loadingPage"
        style="width: 100%"
      >
        <el-table-column
          min-width="200"
          prop="id"
          label="卡号"
        ></el-table-column>
        <el-table-column min-width="130" prop="owner_name" label="使用部门">
        </el-table-column>
        <el-table-column min-width="120" prop="plan_name" label="测评任务名称">
        </el-table-column>
        <el-table-column min-width="100" prop="user_name" label="测评用户名称">
        </el-table-column>
        <el-table-column min-width="100" prop="status_label" label="使用状态">
        </el-table-column>
        <el-table-column
          min-width="100"
          prop="update_datetime"
          label="使用日期"
        >
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      v-bind:child-msg="pageparm"
      @callFather="callFather"
    ></Pagination>
    <addauthorizationVip
      ref="addauthorizationVipref"
      @refreshData="getData"
    ></addauthorizationVip>
  </div>
</template>
<script>
import addauthorizationVip from "./addauthorizationVip.vue";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { owner_count, card_list, singleStdelete, deptproduct, options, singleStput, deptSchoolPeo, groupget, exportStudents, url_other_ } from '@/api/api'
export default {
  components: {
    Pagination,
    addauthorizationVip
    // addpeoGroup
  },
  name: 'authorizationVip',
  data() {
    return {
      allData: {
        not_used: 0,
        used: 0,
        all: 0
      },
      deptList: [],
      optionschool: [],
      dateList: [],
      props: { multiple: true, value: 'id', label: 'name' },
      options: [],
      objArrDetle: [],
      userlist: [],// pingce
      productsgetlist: [], // fangam
      isFull: false,
      visible: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        user_name: '',
        owner_name: '',
        vip: '',
        status: '',
        page: 1,
        limit: 10,
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [],
      getGroupList: [],
      timers: [],
      tableData: []
    }
  },
  methods: {
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    addAdmin() {
      this.$refs.addauthorizationVipref.addAdminFn(null, '授权')
    },
    getowner_count() {
      owner_count().then(res => {

        if (res.code == 2000) {
          this.allData = res.data.data[0]
        }
      })
    },
    handleEdit(row, flag) {
      if (flag == 'com') {
        window.open(url_other_ + '#/?id=' + row.id, '_blank');
      }
      if (flag == 'open') {
        window.open(url_other_, '_blank');
      }
      else if (flag == 'edit') {
        this.$refs.addStudentsEvaluateflag.addAdminFn(row, '编辑')
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的任务么？', {
          closeOnClickModal: false
        }).then(res => {
          singleStdelete(row).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      } else if (flag == 'status') {
        let vm = this
        vm.$confirm('您确定要完成选中的任务么？', {
          closeOnClickModal: false
        }).then(res => {
          row['status'] = '3'
          singleStput(row).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }
      else if (flag == "reset") {
        this.optionschool = []
        this.formInline = {
          user_name: '',
          owner_name: '',
          vip: '',
          status: '',
          page: 1,
          limit: 10,
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.dateList = []
        this.getData()
      }
    },

    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },

    async getDataproductsget() {
      deptproduct({ page: 1, limit: 999 }).then(res => {
        if (res.code == 2000) {
          this.productsgetlist = res.data.data
        }
      })
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      card_list(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
          this.tableData = res.data.data

          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight) - 100
    }
  },
  created() {
    this.getData()
    this.getowner_count()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },
  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
<style scoped lang="scss">
.vipTotal {
  justify-content: center;
  ::v-deep .el-form-item {
    padding-right: 20px;
  }
  ::v-deep .el-form-item__label {
    font-size: 20px;
  }
  .spanVip {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
