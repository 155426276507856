<template>
  <div>
    <ly-dialog
      v-model="dialogVisible"
      :title="loadingTitle"
      width="640px"
      :before-close="handleClose"
    >
      <el-form
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
          <el-form-item label="创建VIP卡数量：" prop="card_count">
          <el-input-number
            :controls="false"
            placeholder="创建VIP卡数量"
            v-model.trim="formData.card_count"
          ></el-input-number>
        </el-form-item>

      
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave"
          >确定</el-button
        >
      </template>
    </ly-dialog>
  </div>
</template>
<script>
import { batch_create } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util";
export default {
  components: { LyDialog },
  emits: ['refreshData'],
  name: "addStudentsEvaluate",
  data() {
    return {
      userlist: [],
      loading: false,
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
        "card_count": null,
      },
      rules: {
      
        card_count: [
          { required: true, message: '请填写创建数量', trigger: 'blur' }
        ]
      },
      rolelist: [],
      options: [],
    }
  },
  watch: {

  },
  methods: {

    remoteMethod(query) {
      if (query !== '') {
        this.getDataUsersUsers(query)
      } else {
        this.userlist = [];
      }
    },
    getDataUsersUsers(name) {
      this.loading = true;
      UsersfrontUsers({ page: 1, limit: 999, name: name }).then(res => {
        if (res.code == 2000) {
          this.loading = false;
          this.userlist = res.data.data
        }
      })
    },
    handlerPicker(e) {
      this.formData.begin_datetime = e[0]
      this.formData.end_datetime = e[1]
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.formData = {
        "card_count": null,
      }
    },
    addAdminFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true

    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = JSON.parse(JSON.stringify(this.formData))

            batch_create(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          

        }
      })
    },



  }
}
</script>

