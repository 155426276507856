<template>
  <div>
    <ly-dialog
      v-model="dialogVisible"
      :title="loadingTitle"
      width="640px"
      :before-close="handleClose"
    >
      <el-form
        :inline="false"
        :model="formData"
        :rules="rules"
        ref="rulesForm"
        label-position="right"
        label-width="auto"
      >
            <el-form-item label="可授权数量：">

          <span>{{ num }}</span>
         </el-form-item >
           <el-form-item label="授权部门：" prop="dept_id">
          <el-select
            v-model="formData.dept_id"
            filterable
            clearable
            placeholder="授权部门"
            
            remote
          
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in deptList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
      </el-form-item>
          <el-form-item label="数量：" prop="card_count">
          <el-input-number
            :controls="false"
            placeholder="数量"
            v-model.trim="formData.card_count"
          ></el-input-number>
        </el-form-item>

      
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave"
          >确定</el-button
        >
      </template>
    </ly-dialog>
  </div>
</template>
<script>
import { change_owner,owner_count,search_dept } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util";
export default {
  components: { LyDialog },
  emits: ['refreshData'],
  name: "addStudentsEvaluate",
  data() {
    return {
      num: 0,
      deptList:[],
      userlist: [],
      loading: false,
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
        "card_count": null,
      },
      rules: {
      
        card_count: [
          { required: true, message: '请填写创建数量', trigger: 'blur' }
        ],
        dept_id: [
           { required: true, message: '请填写授权部门', trigger: 'blur' }
        ]
      },
      rolelist: [],
      options: [],
    }
  },
  watch: {

  },
  methods: {
  getsearch_dept(params) {
      search_dept(params).then(res => {
    this.deptList =  res.data
       
      })
    },
    remoteMethod(query) {
      this.loadingschool = true;
      if (query !== '') {
        this.getDataUsersUsers(query)
      } else {
        this.deptList = [];
      }
    },
    getDataUsersUsers(name) {

      this.getsearch_dept({dept:name})
    },
    handlerPicker(e) {
      this.formData.begin_datetime = e[0]
      this.formData.end_datetime = e[1]
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.formData = {
        "card_count": null,
      }
    },
    addAdminFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      this.getsearch_dept()
         owner_count().then(res => {
          
              if (res.code == 2000) {
               this.num = res.data.data[0][res.data.data[0].auth_type]
              } else {
                this.$message.warning(res.msg)
              }
            })
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = JSON.parse(JSON.stringify(this.formData))
          if (param.card_count > this.num) {
            this.$message.error('授权数量大于可授权数量')
            this.loadingSave = false
          } else { 
          change_owner(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }
  
          

        }
      })
    },



  }
}
</script>

